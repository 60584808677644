<template lang="pug">
v-dialog(v-model="showing" max-width=460)
  
  template(#activator="{on}")
    slot(:showing="showing" :show="show" :send="_send")
      v-badge(right :color="countColor" overlap).result-badge
        template(v-slot:badge)
          span {{ messageSentCount }}
        v-icon(v-on="on") mdi-email
  v-card
    v-card-title Send email and SMS
    v-card-text Are you sure you want to send Afwezigen email and SMS?
    v-card-actions
      v-spacer
      v-btn(color="error" text @click="close") Cancel
      v-btn(color="success" text @click="send") Send
</template>

<script>
import Svc from "../core/results-service"
export default {
  props: {
    ID: Number,
    messageSentCount: Number
  },

  data: () => ({
    showing: false
  }),

  computed: {
    countColor() {
      if(!this.messageSentCount) return 'primary'
      return 'warning'
    }
  },

  methods: {
    async send() {
      await this._send()
      this.$notify({type: 'success', text: 'Message sent'})
      this.showing = false
    },

    async _send() {
      this.$emit('send:start')
      await new Svc().sendResult(this.ID)
      this.$emit('send:end')
    },

    show() {this.showing = true},

    close() {
      this.showing = false
    }
  }
}
</script>

<style lang="scss">
.result-badge {
  .v-badge__badge {
    min-width: 16px;
    height: 16px;
    font-size: 12px;
  }
}

</style>